import loader from "@/services/loader.service";
import notify from "@/services/notify.service";
import { filterBuilder } from "@/services/filterBuilder.service";
import storage from "@/services/storage.service";

const defaultSortingOptions = {
  column: "id",
  direction: "asc",
};

export default {
  methods: {
    /**
     * @param { Object } gqlQuery
     * @param { Object } options
     * @param { Object } settings
     * @returns { Object } api response
     */

    async $get(gqlQuery, options = {}, settings = {}) {
      const { withLoader = true, withNotify = false, delaySuccessNotify = false } = settings;

      try {
        if (withLoader) loader.on();

        if (Object.keys(options).length) {
          if (options?.filtersOptions?.length) {
            const filtersOptions = JSON.parse(JSON.stringify(options.filtersOptions));

            options.filters = filterBuilder(filtersOptions);
          }

          options = {
            ...options,
            orderBy: options.sortingOptions || defaultSortingOptions,
            page: options.page || 1,
            perPage: options.perPage || 25,
          };
        }

        const response = await this.$apollo.query({
          query: gqlQuery,
          variables: options,
        });

        if (withNotify) {
          const [message] = Object.keys(response.data);

          if (delaySuccessNotify) {
            const type = "success";

            storage.setObj("notify", { type, message });
          } else {
            notify.success(message);
          }
        }

        return response.data;
      } catch (error) {
        if (withNotify) showNotifyError(error);
      } finally {
        loader.off();
      }
    },

    /**
     * @param { Object } gqlMutations
     * @param { Object } variables
     * @param { Object } settings
     * @returns {Promise<object>}
     */

    async $post(gqlMutations, variables, settings = {}) {
      const { withLoader = true, withNotify = true, delaySuccessNotify = false } = settings;

      try {
        if (withLoader) loader.on();

        const response = await this.$apollo.mutate({
          mutation: gqlMutations,
          variables: variables,
        });

        if (withNotify) {
          const [message] = Object.keys(response.data);

          if (delaySuccessNotify) {
            const type = "success";

            storage.setObj("notify", { type, message });
          } else {
            notify.success(message);
          }
        }

        return response.data;
      } catch (error) {
        if (withNotify) showNotifyError(error);
      } finally {
        loader.off();
      }
    },
  },
};

function showNotifyError(error) {
  if (error.graphQLErrors.length) {
    error.graphQLErrors.forEach((errorItem) => {
      const message = errorItem?.extensions?.message;

      if (Array.isArray(message) && message.length) {
        message.forEach((code) => notify.error(code));
      } else {
        notify.error(message);
      }
    });
  } else {
    notify.error();
  }
}
